import React, { useState } from 'react';
import { Form, Formik, yupToFormErrors } from 'formik';
import * as yup from 'yup';

import { Box, Button } from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

import { ChooseYourGoal } from './Steps/ChooseYourGoal';
import { CheckBox } from './Steps/CheckBox';
import { NeverGiveUp } from './Steps/NeverGiveUp';
import { WeightSlider } from './Steps/WeightSlider';
import { WeightDetails } from './Steps/WeightDetails';
import { Summary } from './Steps/Summary';

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const Wizard = ({ children, initialValues, onSubmit, props }) => {
    const [stepNumber, setStepNumber] = useState(0);
    const steps = React.Children.toArray(children);
    const [snapshot, setSnapshot] = useState(initialValues);

    const step = steps[stepNumber];
    const totalSteps = steps.length;
    const isLastStep = stepNumber === totalSteps - 1;

    const next = values => {
        setSnapshot(values);
        setStepNumber(Math.min(stepNumber + 1, totalSteps - 1));
    };

    const previous = values => {
        setSnapshot(values);
        setStepNumber(Math.max(stepNumber - 1, 0));
    };

    const handleSubmit = async (values, bag) => {
        if (step.props.onSubmit) {
            await step.props.onSubmit(values, bag);
        }
        if (isLastStep) {
            return onSubmit(values, bag);
        } else {
            typeof bag.setTouched === 'function' && bag.setTouched({});
            next(values);
        }
    };

    return (
        <Formik
            initialValues={snapshot}
            onSubmit={handleSubmit}
            validationSchema={step.props.validationSchema}
        >
            {(formik) => (
                <Form>
                    <Box sx={{ height: '100%' }}>
                        {formik.isSubmitting ? (
                            <Box>Submitting...</Box>
                        ) : (
                            <>
                                {React.cloneElement(step, { next: handleSubmit, form: formik })}
                                <Box sx={{ display: 'flex' }} justifyContent={{ xs: 'center' }} mt={5} className="navigation-btns">
                                    {stepNumber > 0 && (
                                        <Button onClick={() => previous(formik.values)} variant="contained">
                                            Back
                                        </Button>
                                    )}
                                    {stepNumber > 0 && (
                                        <Button disabled={formik.isSubmitting} type="submit" variant="contained" endIcon={<ArrowRightAltIcon />}>{isLastStep ? 'Submit' : 'Continue'}</Button>
                                    )}
                                </Box>
                            </>
                        )}
                    </Box>
                </Form>
            )
            }
        </Formik >
    );
};

const WizardStep = ({ children, next, form }) => {
    return React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, { next, form })
        }
        return null;
    });
};

export const FormikStepper = (props) => {

    return (
        <Wizard
            initialValues={{
                checked: [],
                // slider: 20,
            }}
            onSubmit={async values =>
                sleep(300).then(() => console.log('Wizard submit', values))
            }

        >
            {/* STEP 1 */}
            <WizardStep onSubmit={() => console.log('Step 1 onSubmit')}>
                <ChooseYourGoal next={props.next} />
            </WizardStep>

            {/* STEP 2 */}
            <WizardStep validationSchema={yup.object({
                checked: yup.array().min(1, "Select atleast one option")
            })}>
                <CheckBox />
            </WizardStep>

            {/* STEP 3 */}
            <WizardStep>
                <NeverGiveUp />
            </WizardStep>

            {/* STEP 4 */}
            <WizardStep>
                <WeightSlider />
            </WizardStep>

            {/* STEP 5 */}
            <WizardStep
                validationSchema={yup.object({
                    weight: yup.number().required('Weight is required')
                        .min(30, 'Your weight must be at least 30kg'),

                    targetWeight: yup.number()
                        .lessThan(yup.ref("weight")).required('Target weight is required'),

                    height: yup.number().required('Height is required'),

                    age: yup.number().required('Age is required')
                })}
            >
                <WeightDetails />
            </WizardStep>
        </Wizard >

    )
}
