import { yellow } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    palette: {
        primary: {
            main: yellow[600]
        },
        secondary: {
            main: '#000',
        },
    },
});