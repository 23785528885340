import React, { useEffect, useState } from 'react'

import Slider from '@mui/material/Slider';
import { Box } from '@mui/material';
// import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Field } from 'formik';
import women_1 from '../../assets/body_shapes/woman_01.png'
import women_2 from '../../assets/body_shapes/woman_02.png'
import women_3 from '../../assets/body_shapes/woman_03.png'
import women_4 from '../../assets/body_shapes/woman_04.png'
import women_5 from '../../assets/body_shapes/woman_05.png'

export const WeightSlider = ({ form }) => {

    const [bodyShape, setBodyShape] = useState(women_2);
    const [slideValue, setSlideValue] = useState(0);

    useEffect(() => {
        form.setFieldValue('slider', slideValue);
    }, [slideValue]);

    console.log(slideValue);

    const changeImage = (value) => {

        switch (true) {
            case (value === 10):
                setBodyShape(women_1);
                break;
            case (value === 20):
                setBodyShape(women_2);
                break;
            case (value === 30):
                setBodyShape(women_3);
                break;
            case (value === 40):
                setBodyShape(women_4);
                break;
            case (value === 50):
                setBodyShape(women_5);
                break;
            default:
                break;
        }
    }

    const marks = [
        {
            value: 10,
            label: '5-10%',
        },
        {
            value: 20,
            label: '',
        },
        {
            value: 30,
            label: '',
        },
        {
            value: 40,
            label: '',
        },
        {
            value: 50,
            label: '',
        },
    ];


    return (
        <Box flexDirection={{ xs: 'column', md: 'row' }} sx={{ display: 'flex', height: '100%' }}>
            <Box
                width={{ md: '50%' }}
                height={{ xs: '50vh', md: '100%' }}
                pt={{ xs: 2, md: 0 }}
                alignItems={{ xs: 'flex-start', md: 'center' }}
                sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center', backgroundColor: 'white' }}>
                <img src={bodyShape} alt="Body Shape" />
            </Box>
            <Box
                height={{ xs: '50vh', md: '100%' }}
                width={{ xs: '100%', md: '50%' }}
                alignItems={{ xs: 'center', md: 'flex-start' }}
                justifyContent={{ xs: 'flex-start', md: 'center' }}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: 'lightgray'
                }}>

                <Box p={{ xs: 4, md: 10 }}>
                    <Box mb={{ xs: 2, sm: 6 }}>
                        <h1>Choose your level of body fat?</h1>
                    </Box>

                    <Field name={'slider'} component={'input'} type={'hidden'} />
                    <Slider
                        aria-label="Small"
                        valueLabelDisplay="auto"
                        defaultValue={20}
                        step={10}
                        marks={marks}
                        min={10}
                        max={50}
                        onChange={(event, value) => {
                            setSlideValue(value);
                            changeImage(value);
                        }}
                    />
                </Box>

            </Box>
        </Box >
    )
}