import './App.scss';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './components/Theme/theme';
import { FormikStepper } from './components/FormikStepper';

function App() {

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <FormikStepper />
      </div>
    </ThemeProvider>
  );
}

export default App;
