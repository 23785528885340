import React from 'react';
import { Box, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import { Field } from 'formik';


function createAgeRange(start, end) {
    return Array(end - start + 1).fill().map((_, idx) => start + idx)
}

export const WeightDetails = (props) => {

    const ageRange = createAgeRange(18, 75);

    return (
        <Box flexDirection={{ xs: 'column', md: 'row' }} sx={{ display: 'flex', height: '100%' }}>
            <Box sx={{ display: 'flex', flexFlow: 'column', margin: '0 auto', width: '100%', maxWidth: '500px', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ width: '100%' }} className="form-selection-container">
                    <Box p={2}>
                        <h2>Title goes here</h2>
                        <p>Get a personalized workout program</p>
                    </Box>
                    <Box className="checkbox-container">
                        <Box className="MarhabaP">
                            <Field className="Marhaba">
                                {({
                                    field,
                                    form: { touched, errors },
                                    meta,
                                }) => (
                                    <Box>
                                        <FormControl {...field} width="100%">
                                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                <Box mr={0.5}>
                                                    <TextField
                                                        name="weight"
                                                        label="Weight"
                                                        id="weight"
                                                        type="number"
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">kg</InputAdornment>,
                                                        }}
                                                        helperText={meta.touched && meta.error.weight && (
                                                            <div className="error">{meta.error.weight}</div>
                                                        )}
                                                    />
                                                </Box>
                                                <Box ml={0.5}>
                                                    <TextField
                                                        name="targetWeight"
                                                        label="Target weight"
                                                        id="target-weight"
                                                        type="number"
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">kg</InputAdornment>,
                                                        }}
                                                        helperText={meta.touched && meta.error.targetWeight && (
                                                            <div className="error">{meta.error.targetWeight}</div>
                                                        )}
                                                    />
                                                </Box>
                                            </Box>
                                            <Box mt={3}>
                                                <TextField
                                                    fullWidth
                                                    name="height"
                                                    label="Height"
                                                    id="height"
                                                    type="number"
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">cm</InputAdornment>,
                                                    }}
                                                    helperText={meta.touched && meta.error && (
                                                        <div className="error">{meta.error.height}</div>
                                                    )}
                                                />
                                            </Box>
                                            <Box mt={3}>
                                                <TextField
                                                    fullWidth
                                                    id="age"
                                                    name="age"
                                                    select
                                                    label="Age"
                                                    SelectProps={{
                                                        native: true,
                                                    }}
                                                    helperText={meta.touched && meta.error && (
                                                        <div className="error">{meta.error.age}</div>
                                                    )}
                                                >
                                                    {ageRange.map(index => {
                                                        return (
                                                            <option key={index} value={index}>
                                                                {index}
                                                            </option>
                                                        )
                                                    })}
                                                </TextField>
                                            </Box>
                                        </FormControl>
                                    </Box>
                                )}
                            </Field>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box >
    )
}
