import React, { useState } from 'react';
import { Box, FormControlLabel } from '@mui/material'
import Checkbox from '@mui/material/Checkbox';
import FormHelperText from '@mui/material/FormHelperText';

// import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Field } from 'formik';

const CheckList = [
    {
        label: "Option 1",
        value: "one"
    },
    {
        label: "Option 2",
        value: "two"
    },
    {
        label: "Option 3",
        value: "three"
    },
    {
        label: "Option 4",
        value: "four"
    }
]

export const CheckBox = (props) => {

    return (
        <Box flexDirection={{ xs: 'column', md: 'row' }} sx={{ display: 'flex', height: '100%' }}>
            <Box sx={{ display: 'flex', flexFlow: 'column', margin: '0 auto', width: '100%', maxWidth: '500px', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ width: '100%' }} className="form-selection-container">
                    <Box p={2}>
                        <h2>Choose your additional goals?</h2>
                        <p>Get a personalized workout program</p>
                    </Box>
                    <Box className="checkbox-container">
                        {CheckList.map((item, index) => {
                            return (
                                <Field name="checked" key={index}>
                                    {({ field, meta }) => (
                                        <>
                                            <FormControlLabel
                                                {...field}
                                                className="custom-checkbox"
                                                label={item.label}
                                                control={<Checkbox
                                                    name="checked"
                                                    value={item.value}
                                                />
                                                }
                                            />
                                        </>
                                    )}
                                </Field>
                            )
                        })}
                        <Field name="checked">
                            {({ field, meta }) => (
                                <>
                                    <FormControlLabel
                                        {...field}
                                        className="custom-checkbox"
                                        label="None of above"
                                        control={<Checkbox
                                            name="checked"
                                            value="none of above"
                                        />
                                        }
                                    />
                                </>
                            )}
                        </Field>
                        {/* <FormHelperText>You can display an error</FormHelperText> */}
                    </Box>
                </Box>
            </Box>
        </Box >
    )
}
