import React from 'react';
import { Box, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { Field } from 'formik';
import option_1 from './../../assets/body_shapes/woman_01.png';
import option_2 from './../../assets/body_shapes/woman_02.png';
import option_3 from './../../assets/body_shapes/woman_03.png';

export const bodyType = [
    {
        title: "Option 1",
        url: option_1
    },
    {
        title: "Option 2",
        url: option_2
    },
    {
        title: "Option 3",
        url: option_3
    }
]

export const ChooseYourGoal = (props) => {

    const [value, setValue] = React.useState(false);

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    return (
        <Box flexDirection={{ xs: 'column', md: 'row' }} sx={{ display: 'flex', height: '100%' }}>
            <Box sx={{ display: 'flex', flexFlow: 'column', margin: '0 auto', width: '100%', maxWidth: '500px', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ width: '100%' }} className="form-selection-container">
                    <Box p={2}>
                        <h2>Choose your goal</h2>
                        <p>Get a personalized workout program</p>
                    </Box>
                    <Box className="custom-radio-btn-container">
                        <RadioGroup
                            name="goals"
                            value={value}
                            onChange={handleChange}>
                            {bodyType.map((item, index) => {
                                return (
                                    <Field name="goals" key={index}>
                                        {({ field }) => (
                                            <FormControlLabel
                                                {...field}
                                                label={false}
                                                control={
                                                    <Box className="single-radio-wrapper">
                                                        <Radio
                                                            value={item.title}
                                                            sx={{
                                                                fontSize: '40px',
                                                                fontWeight: 'medium',
                                                            }}
                                                            className="custom-radio-btn"
                                                            onChange={props.next}
                                                        />
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                justifyContent: 'space-between',
                                                                marginBottom: '10',
                                                                width: '100%',
                                                                overflow: 'hidden',
                                                                background: 'lightgray'
                                                            }} height={{ xs: 140, sm: 170 }}>
                                                            <Box sx={{ fontWeight: 'bold', display: 'flex', justifyContent: 'center', flexDirection: 'column', px: '20px' }}>
                                                                <h2>{item.title}</h2>
                                                            </Box>
                                                            <Box sx={{ minWidth: '100px' }}>
                                                                <img src={item.url} width="150px" alt="Body Shape" />
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                }
                                            />
                                        )}
                                    </Field>
                                )
                            })}
                        </RadioGroup>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
