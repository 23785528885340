import React from 'react'
import Box from '@mui/material/Box';

import women_1 from '../../assets/body_shapes/woman_01.png'

export const NeverGiveUp = (props) => {
    return (
        <Box flexDirection={{ xs: 'column', md: 'row' }} sx={{ display: 'flex', height: '100%' }}>
            <Box
                pt={{ xs: 2, md: 0 }}
                width={{ md: '50%' }}
                height={{ xs: '50vh', md: '100%' }}
                alignItems={{ xs: 'flex-start', md: 'center' }}
                sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center', backgroundColor: 'white' }}>
                {/* <Box className="continuous-image" sx={{ width: '256px' }} minHeight={{ xs: '400px', md: '648px' }} alt="Body Shape"></Box> */}
                <img src={women_1} alt="Body Shape" />
            </Box>
            <Box
                pb={{ xs: 5, sm: 0 }}
                height={{ xs: '50vh', md: '100%' }}
                width={{ xs: '100%', md: '50%' }}
                alignItems={{ xs: 'center', md: 'flex-start' }}
                sx={{
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: 'lightgray'
                }}>
                <Box p={{ xs: 4, md: 10 }}>
                    <Box mb={6}>
                        <h1>Why do people give up on excercise?</h1>
                        <p>
                            <strong>The #1 reason is starting too big too quickly</strong>
                        </p>
                        <p>You’ll achieve much more than just a few weeks of exercising.</p>
                        <p>We won’t promise you quick results. Our program’s primary goal is to change your lifestyle for the better.</p>
                    </Box>
                    {/* <Box sx={{ display: 'flex' }} justifyContent={{ xs: 'center', md: 'end' }} mt={5}>
                        <Button onClick={props.next} variant="contained" endIcon={<ArrowRightAltIcon />}>Continue</Button>
                    </Box> */}
                </Box>
            </Box>
        </Box>
    )
}
